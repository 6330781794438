import React, { useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import inpLogo from "../images/logo1.png";
import idexLogo from "../images/logo2.png"
import comueLogo from "../images/logo3.png";
import araLogo from "../images/IDEX_france2030_couleur.jpg"
import Logo2030 from "../images/logo5.png";
import idoLogo from "../images/Logo_ido-data_Couleurs_2020_WEB.png";
import DownloadLogo from "../images/Download-logo.png";
import YouTubeLogo from "../images/YouTube_Logo.png";
import YouTubeQRCode from "../images/Youtube-QRCode.png";

import sgbLogo from "../images/Logo SGB Escape Blanc.png";

import {
  signIn,
  signUp,
  resendConfirmationCode,
  confirmSignUp,
  forgotPassword,
  forgotPasswordSubmit,
} from "../constants/auth";

const footerLogos = [inpLogo, idexLogo, comueLogo, araLogo, idoLogo];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgb(53,97,168)",
    textAlign: "center",
    overflow: "hidden",
  },
  progress: {
    // margin: theme.spacing(2),
    padding: 0,
    // width: 40,
    // height: 40,
    // color: "blue",
  },
  error: {
    color: "red",
    fontSize: "0.9em",
  },
  info: {
    color: "green",
    fontSize: "0.9em",
  },
  loadingErrorContainer: {
    minHeight: "5em",
  },
  logo: {
    // padding: "10%",
    objectFit: "contain",
    width: "100%",
    height: "100%",
    // width: "30%",
  },
  idoDataLogo: {
    margin: 10,
    width: "20%",
  },
  formContainer: {
    // marginTop: -50,
    padding: 30,
    marginBottom: 30,
    // paddingTop: 30,
    // paddingLeft: 30,
    // paddingRight: 30,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    "& > *": {
      // margin: theme.spacing(1),
      width: "40ch",
    },
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  // input: {
  //   color: "white",
  //   borderBottomColor: "white",
  //   borderColor: "white",
  //   "& label.Mui-focused": {
  //     color: "white",
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor: "white",
  //   },
  // "& .MuiOutlinedInput-root": {
  // "& fieldset": {
  // borderColor: "white",
  // },
  //     "&:hover fieldset": {
  //       borderColor: "white",
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: "white",
  //     },
  // },
  // },
}));

const SignInScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [codeInput, setCodeInput] = useState(false);
  const [code, setCode] = useState("");
  const [info, setInfo] = useState({ type: "error", message: "" });
  const [forgotPasswordForm, setForgotPasswordForm] = useState(false);
  const [forgotPasswordConfirm, setForgotPasswordConfirm] = useState(false);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = await signIn(email, password).catch((error) => {
      if (error.name === "UserNotConfirmedException") {
        console.log(error.message);
        setInfo({ type: "error", message: error.message });
        setCodeInput(true);
      } else {
        setInfo({ type: "error", message: "Invalid credentials" });
      }
      setLoading(false);
    });
    console.log({ user });
  };

  const handleSignUp = async () => {
    if (password.length < 8) {
      setInfo({
        type: "error",
        message:
          "Password should be at least 8 characters long, it should contain at least one number, one uppercase character and one lowercase character.",
      });
      return;
    }
    setLoading(true);
    const user = await signUp(email, password).catch((err) => {
      setInfo({
        type: "error",
        message: err.message.replace("Username", "Email"),
      });
      setLoading(false);
      return;
    });
    console.log({ user });
    if (user) {
      setInfo({
        type: "info",
        message: "A confirmation code was sent to your email.",
      });
      setCodeInput(true);
      setLoading(false);
    }
  };

  const handleResendConfirmationCode = () => {
    setLoading(true);
    resendConfirmationCode(email)
      .then(() => {
        setInfo({
          type: "info",
          message: "A confirmation code was sent to your email.",
        });
        setLoading(false);
      })
      .catch((error) => {
        setInfo({
          type: "error",
          message: error.message,
        });
        setLoading(false);
      });
  };

  const handleConfirmSignUp = () => {
    setLoading(true);
    confirmSignUp(email, code)
      .then(() => {
        setInfo({
          type: "info",
          message: "Account successfully confirmed! You can now login.",
        });
        setLoading(false);
        setCodeInput(false);
        setCode("");
      })
      .catch((error) => {
        setInfo({
          type: "error",
          message: error.message,
        });
        setLoading(false);
      });
  };

  const toggleForgotPassword = () => {
    setForgotPasswordForm(!forgotPasswordForm);
    setForgotPasswordConfirm(false);
    setInfo({ type: "error", message: "" });
  };

  const handleForgotPassword = () => {
    setLoading(true);
    forgotPassword(email)
      .then((data) => {
        console.log({ data });
        setInfo({
          type: "info",
          message: "A verification code was sent to your email",
        });
        setLoading(false);
        setForgotPasswordConfirm(true);
      })
      .catch((error) => {
        setInfo({
          type: "error",
          message: error.message,
        });
        setLoading(false);
      });
  };

  const handleForgotPasswordConfirm = () => {
    setLoading(true);
    forgotPasswordSubmit(email, code, password)
      .then((data) => {
        console.log({ data });
        setInfo({
          type: "info",
          message:
            "Password successfully changed ! Go back to login and login with your new password.",
        });
        setLoading(false);
      })
      .catch((error) => {
        setInfo({
          type: "error",
          message: error.message,
        });
        setLoading(false);
      });
  };

  return (
    <div className={classes.container}>
      <div style={{ maxHeight: "15%", margin: "2%" }}>
        <img src={sgbLogo} className={classes.logo} alt="Grenoble INP" />
      </div>
      {/* <div className={classes.iconsContainer}>
        <img src={logo} className={classes.logo} alt="Grenoble INP" />
        <img src={idoDataLogo} className={classes.idoDataLogo} alt="Ido-Data" />
      </div> */}
      <Paper className={classes.formContainer} elevation={24}>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSignIn}
        >
          <TextField
            label="Email"
            value={email}
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
            className={classes.input}
          />
          {(!forgotPasswordForm || forgotPasswordConfirm) && (
            <TextField
              label={forgotPasswordConfirm ? "New password" : "Password"}
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {(codeInput || forgotPasswordConfirm) && (
            <TextField
              label="Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          )}
          <div className={classes.loadingErrorContainer}>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <p
                className={info.type === "error" ? classes.error : classes.info}
              >
                {info.message && !loading ? info.message : ""}
              </p>
            )}
          </div>
          {codeInput && (
            <>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="button"
                className={classes.submit}
                onClick={handleConfirmSignUp}
              >
                Confirm sign up
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="button"
                className={classes.submit}
                onClick={handleResendConfirmationCode}
              >
                Resend confirmation code
              </Button>
            </>
          )}
          {!codeInput && (
            <>
              {!forgotPasswordForm ? (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.submit}
                  >
                    Sign in
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="button"
                    className={classes.submit}
                    onClick={handleSignUp}
                  >
                    Create account
                  </Button>
                </>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="button"
                  className={classes.submit}
                  onClick={
                    forgotPasswordConfirm
                      ? handleForgotPasswordConfirm
                      : handleForgotPassword
                  }
                >
                  {forgotPasswordConfirm ? "Change password" : "Send code"}
                </Button>
              )}
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                type="button"
                className={classes.submit}
                onClick={toggleForgotPassword}
              >
                {forgotPasswordForm ? "Back to sign in" : "Forgot Password ?"}
              </Button>
            </>
          )}
        </form>
      </Paper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight: 100,
          // backgroundColor: "white",
          width: "100%",
          paddingLeft: "2%",
          paddingRight: "2%",
          justifyContent: "space-between",
        }}
      >
        <a
          href="https://inp-public-read.s3.eu-west-1.amazonaws.com/smart-games-builder-escape-2024-12-10.apk"
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "1%",
            paddingRight: "1%",
            marginTop: 8,
            marginBottom: 8,
            // border: "1px solid",
          }}
        >
          <img src={DownloadLogo} className={classes.logo} alt="Télécharger" />
        </a>
        <a
          href="https://youtu.be/47Y2IkvN194?si=Pw2mhi9gL96K3Evp"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "1%",
            paddingRight: "1%",
            marginTop: 8,
            marginBottom: 8,
            // border: "1px solid",
          }}
        >
          <img
            src={YouTubeQRCode}
            className={classes.logo}
            alt="Youtube"
            style={{ width: "20%" }}
          />
          <img
            src={YouTubeLogo}
            className={classes.logo}
            alt="Youtube"
            style={{ width: undefined, objectPosition: "left center" }}
          />
        </a>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxHeight: 100,
            backgroundColor: "white",
            width: "100%",
            paddingLeft: "2%",
            paddingRight: "2%",
            justifyContent: "space-between",
          }}
        >
          {footerLogos.map((logoItem, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "1%",
                paddingRight: "1%",
                marginTop: 8,
                marginBottom: 8,
                // border: "1px solid",
              }}
            >
              <img src={logoItem} className={classes.logo} alt="Grenoble INP" />
            </div>
          ))}
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxHeight: 100,
            backgroundColor: "white",
            width: "100%",
            paddingLeft: "2%",
            paddingRight: "2%",
            justifyContent: "space-between",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "1%",
              paddingRight: "1%",
            }}
          >
            <a href="" target="_blank">
              Mentions légales
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "1%",
              paddingRight: "1%",
            }}
          >
            <a href="" target="_blank">
              Donnéées personnelles
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default connect()(SignInScreen);
