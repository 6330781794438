import { graphqlOperation, API } from "aws-amplify";
import store from "../redux/store";
import { listUniverses } from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { alphabeticalSort } from "./helpers";

const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gameUniverseId
        createdAt
        createdBy
        title
        author
        institution
        description
        masterCode
        timer
        lives
        introAudioUrl
        introVideoUrl
        introText
        penalityTimer
        penalityCounter
        penalityLife
        inventory {
          type
          title
          url
          s3key
        }
        questions {
          index
          type
          question
          hint
          qrCode
          text
          correctAnswer
          assetType
          answers {
            description
            value
          }
          image {
            type
            title
            url
            s3key
          }
          video {
            type
            title
            url
            s3key
          }
          audio {
            type
            title
            url
            s3key
          }
        }
        universe {
          id
          author
          createdAt
          createdBy
          title
          introText
          updatedAt
        }
        updatedAt
        qrCode
      }
      nextToken
    }
  }
`;

export const fetchUniverses = async () => {
  try {
    const queryResult = await API.graphql(graphqlOperation(listUniverses));
    console.log({ queryResult });
    store.dispatch({
      type: "SET_UNIVERSES",
      data: queryResult.data.listUniverses.items,
    });
  } catch (error) {
    console.log({ error });
  }
};

export const deleteUniverse = async (id) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteUniverse, { input: { id } })
    );
    fetchUniverses();
  } catch (error) {
    console.log({ error });
  }
};

export const fetchGames = async () => {
  try {
    const queryResult = await API.graphql(graphqlOperation(listGames));
    alphabeticalSort(
      queryResult.data.listGames.items,
      (item) => item.createdAt,
      "desc"
    );
    store.dispatch({
      type: "SET_GAMES",
      data: queryResult.data.listGames.items,
    });
  } catch (error) {
    console.log({ error });
  }
};

export const deleteGame = async (id) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteGame, { input: { id } })
    );
    fetchGames();
  } catch (error) {
    console.log({ error });
  }
};
