import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Games from "./Games";
import { makeStyles } from "@material-ui/core";
import TabPanel from "../components/TabPanel";
// import GameForm from "./GameForm";
// import GamePage from "./GamePage";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import Universes from "./Universes";
import { fetchUniverses, fetchGames } from "../constants/api";
// import UniverseForm from "./UniverseForm";
import logo from "../images/Logo-SGB-Orange.png";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    background: "rgb(238,238,243)",
  },
  header: {
    display: "flex",
    alignItems: 'center'
  },
});

const Home = ({ dispatch }) => {
  const classes = useStyles();
  const [selectedNav, setSelectedNav] = useState(0);

  const handleNavChange = (event, newValue) => {
    setSelectedNav(newValue);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      console.log({ user });
      if (
        user &&
        user.attributes &&
        user.attributes.sub &&
        user.attributes.email
      ) {
        // console.log({ user });
        dispatch({ type: "SET_USER_ID", data: user.attributes.sub });
        dispatch({ type: "SET_USER_EMAIL", data: user.attributes.email });
      }
    });
    fetchUniverses();
    fetchGames();
  }, [dispatch]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        overflow: "hidden",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <header className={classes.header}>
        <NavBar selectedNav={selectedNav} handleNavChange={handleNavChange} />
      </header>
      <main className={classes.main}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <TabPanel value={selectedNav} index={0}>
            <Games />
          </TabPanel>
          {/* <TabPanel value={selectedNav} index={1}>
          <GameForm />
        </TabPanel> */}
          <TabPanel value={selectedNav} index={1}>
            <Universes />
          </TabPanel>
          {/* <TabPanel value={selectedNav} index={3}>
          <UniverseForm />
        </TabPanel> */}
        </div>
        <div
          style={{
            maxHeight: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <img src={logo} style={{ objectFit: "contain", height: "100%" }} />
        </div>
      </main>
    </div>
  );
};

export default connect()(Home);
