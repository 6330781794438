const QRCode = require("qrcode");

export const encodeToQRCode = (id, email) =>
  new Promise((resolve, reject) => {
    QRCode.toDataURL(
      JSON.stringify({ id, email }),
      { errorCorrectionLevel: "Q", margin: 4, scale: 4 },
      (err, dataURL) => {
        if (err) {
          console.log(err, err.stack);
          reject({ error: "Could not get DataURL" });
        } else {
          resolve(dataURL);
        }
      }
    );
  });

  export const alphabeticalSort = (arr, getter, order = 'asc') =>
    arr.sort(
      order === 'desc'
        ? (a, b) => getter(b).localeCompare(getter(a))
        : (a, b) => getter(a).localeCompare(getter(b))
    );